<template>
  <section id="dashboard">
    <b-row
      v-if="userData.group_id > 99"
    >
      <b-col
        md="2"
        class="mb-2"
      >
        <h5 class="text-capitalize mr-2">
          Tahun Anggaran
        </h5>
        <v-select
          v-model="year"
          required
          :clearable="false"
          name="year_budget"
          :options="year_budget"
          placeholder="Tahun Anggaran"
          class="flex-fill"
          @input="changeYear"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <data-statistics :data="dataMainStat" />
      </b-col>
    </b-row>

    <b-row
      v-if="userData.group_id < 99"
      class="match-height"
    >
      <b-col lg="4">
        <b-row class="match-height">
          <b-col
            md="6"
            cols="6"
          >
            <data-order
              :total-data="dataTotalApp"
              title="Total Permohonan Aplikasi"
            />
          </b-col>
          <b-col
            md="6"
            cols="6"
          >
            <data-order
              :total-data="dataTotalInfra"
              title="Total Permohonan Infrastruktur"
            />
          </b-col>
          <b-col
            v-if="year != 2023"
            md="6"
            cols="6"
          >
            <data-order
              :total-data="dataTotalData"
              title="Total Permohonan Data"
            />
          </b-col>
          <b-col
            v-if="year != 2023"
            md="6"
            cols="6"
          >
            <data-order
              :total-data="dataTotalNon"
              title="Total Permohonan Non Teknis"
            />
          </b-col>
          <b-col
            lg="12"
            md="6"
            cols="12"
          >
            <data-order
              :total-data="dataTotalContinue"
              title="Total Anggaran Direkomendasikan"
              app="Rp."
            />
          </b-col>
          <b-col
            lg="12"
            md="6"
            cols="12"
          >
            <data-order
              :total-data="dataTotalPostpone"
              title="Total Anggaran Tidak Direkomendasikan"
              app="Rp."
            />
          </b-col>
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <data-earnings-chart
              v-if="show"
              :data="dataBelanja"
              title="Jenis Belanja"
            />
          </b-col>
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <data-earnings-chart
              v-if="show"
              :data="dataPengadaan"
              title="Kriteria Belanja"
            />
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <b-col lg="8">
        <data-table :table-data="tableData" />
      </b-col>
      <!--/ Revenue Report Card -->
    </b-row>

    <b-row class="match-height">
      <!-- Arsitektur Layanan -->
      <b-col
        lg="6"
        md="6"
      >
        <data-list
          title="Layanan"
          :list-data="dataAreaLayanan"
        />
      </b-col>

      <!-- Arsitektur Aplikasi -->
      <b-col
        lg="6"
        md="6"
      >
        <data-list
          title="Aplikasi"
          :list-data="dataAreaAplikasi"
        />
      </b-col>

      <!-- Arsitektur Infrastruktur -->
      <b-col
        lg="6"
        md="6"
      >
        <data-list
          title="Infrastruktur"
          :list-data="dataDomainInfra"
        />
      </b-col>

      <!-- Arsitektur Data Informasi -->
      <b-col
        lg="6"
        md="6"
      >
        <data-list
          title="Data & Informasi"
          :list-data="dataPokok"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import vSelect from 'vue-select'
import {
  BRow, BCol,
} from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import DataStatistics from './platform/Statistics.vue'
import DataOrder from './platform/DataOrder.vue'
// import DataMiniStatistik from './platform/DataMiniStatistik.vue'
import DataEarningsChart from './platform/EarningsChart.vue'
import DataTable from './platform/DataTable.vue'
import DataList from './platform/DataList.vue'

export default {
  components: {
    BRow,
    BCol,

    DataStatistics,
    DataOrder,
    // DataMiniStatistik,
    DataEarningsChart,
    DataTable,
    DataList,
    vSelect,
  },
  data() {
    const date1 = new Date().getFullYear()

    return {
      date1,
      year: Number(localStorage.getItem('tahunAnggaran')),
      // year_budget: [date1 - 1, date1, date1 + 1],
      year_budget: [2023, 2024, 2025],
      show: false,
      userData: getUserData(),
      tableData: [],
      dataTotalApp: 0,
      dataTotalInfra: 0,
      dataTotalData: 0,
      dataTotalNon: 0,
      dataTotalContinue: 0,
      dataTotalPostpone: 0,
      dataMainStat: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: 0,
          subtitle: 'Total Permohonan',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-warning',
          title: 0,
          subtitle: 'Selesai Verifikasi Teknis',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'CoffeeIcon',
          color: 'light-danger',
          title: 0,
          subtitle: 'Menunggu Rekomendasi',
          customClass: '',
        },
        {
          icon: 'UserIcon',
          color: 'light-primary',
          title: 0,
          subtitle: 'Menunggu Persetujuan',
          customClass: '',
        },
        {
          icon: 'EditIcon',
          color: 'light-success',
          title: 0,
          subtitle: 'Rekomendasi Selesai',
          customClass: '',
        },
      ],
      dataBelanja: [],
      dataPengadaan: [],
      dataAreaAplikasi: [],
      dataDomainInfra: [],
      dataAreaLayanan: [],
      dataPokok: [],
    }
  },
  mounted() {
    // this.year = 2023
    // localStorage.setItem('tahunAnggaran', this.year.toString())
    if (process.env.VUE_APP_MODE === 'dev') {
      this.year_budget = [this.date1 - 1, this.date1, this.date1 + 1]
    }

    this.$http
      .get('/analytic/dashboard-summary', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        },
      })
      .then(res => {
        if (res.data.status === 'success') {
          this.data = res.data.data
          this.dataMainStat = [
            {
              icon: 'TrendingUpIcon',
              color: 'light-primary',
              title: this.data.permohonan,
              subtitle: 'Permohonan',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'BoxIcon',
              color: 'light-warning',
              title: this.data.verifikasi_teknis,
              subtitle: 'Menunggu Verifikasi Teknis',
              customClass: 'mb-2 mb-sm-0',
            },
            {
              icon: 'CoffeeIcon',
              color: 'light-danger',
              title: this.data.menunggu_rekomendasi,
              subtitle: 'Menunggu Rekomendasi',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: this.data.menunggu_persetujuan,
              subtitle: 'Menunggu Persetujuan',
              customClass: '',
            },
            {
              icon: 'EditIcon',
              color: 'light-success',
              title: this.data.selesai,
              subtitle: 'Rekomendasi Selesai',
              customClass: '',
            },
          ]
          this.dataPengadaan = this.data.jenis_pengadaan
          this.dataBelanja = this.data.jenis_belanja
          this.dataAreaAplikasi = this.data.area_aplikasi
          this.dataDomainInfra = this.data.domain_infra
          this.dataAreaLayanan = this.data.area_layanan
          this.dataPokok = this.data.data_pokok

          this.dataTotalApp = this.data.total_aplikasi
          this.dataTotalInfra = this.data.total_infrastruktur
          this.dataTotalData = this.data.total_data
          this.dataTotalNon = this.data.total_non_teknis
          this.dataTotalContinue = this.data.anggaran_approved
          this.dataTotalPostpone = this.data.anggaran_rejected

          this.show = true
        }
      })
    // ambil hari libur
    this.$http.get('/tanggalan/holidays').then(res => {
      const liburAll = res.data.data
      const libur = []

      Object.keys(liburAll).map(data => {
        libur.push(data)
        return data
      })
      localStorage.setItem('holidays', JSON.stringify(libur))
    })

    if (this.userData.group_id < 99) {
      this.$http
        .get('/analytic/instansi/top-ten', {
          params: {
            token: localStorage.getItem('userToken'),
            tahun_anggaran: localStorage.getItem('tahunAnggaran'),
          },
        })
        .then(res => {
          this.tableData = res.data.data
        })
    }
  },
  methods: {
    changeYear() {
      localStorage.setItem('tahunAnggaran', this.year.toString())
      window.location.reload()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
